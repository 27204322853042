var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "viewTraining__inner" },
    [
      _vm._t("default", function () {
        return [
          _c("span", { staticClass: "viewTraining__inner__h" }, [
            _vm._v(_vm._s(_vm.lesson ? _vm.lesson.name : "Тема обучения")),
          ]),
          _vm.primaryVideo
            ? _c("VideoPlayer", {
                attrs: {
                  lessonId: _vm.lesson.id,
                  saveTime: true,
                  video: _vm.primaryVideo,
                  height: 380,
                  controls: true,
                },
              })
            : _vm._e(),
          !_vm.primaryVideo
            ? _c("VimeoIframeVideo", { attrs: { lesson: _vm.lesson } })
            : _vm._e(),
          _vm.lesson.chat
            ? _c("CAccordeon", {
                attrs: {
                  "accordion-data": [
                    {
                      id: 0,
                      theme: "История чата",
                      shortDescription: _vm.getLineWrap,
                    },
                  ],
                },
              })
            : _vm._e(),
          !_vm.lesson.chat && !_vm.primaryVideo && _vm.lesson.streamUrl
            ? _c(
                "CButton",
                {
                  staticClass: "viewTraining__chat-btn",
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.openChat(_vm.lesson.chatEmbedVimeoCode)
                    },
                  },
                },
                [_vm._v("Chat ")]
              )
            : _vm._e(),
          [
            _vm.lesson.has_homework
              ? _c("span", { staticClass: "viewTraining__inner__h" }, [
                  _vm._v(" " + _vm._s(_vm.$t("lk.training.homeWork")) + " "),
                ])
              : _vm._e(),
            _c("span", {
              staticClass: "viewTraining__inner__desc",
              domProps: { innerHTML: _vm._s(_vm.lesson.descriptionFormatted) },
            }),
          ],
          _vm.additionalMaterials.length
            ? _c(
                "div",
                { staticClass: "viewTraining__additionalMaterials mt-5" },
                [
                  _c("span", { staticClass: "viewTraining__inner__h" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lk.videoEncrypt.additionalMaterials")) +
                        " "
                    ),
                  ]),
                  _vm._l(_vm.additionalMaterials, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "viewTraining__additionalMaterials-item mt-4",
                      },
                      [
                        item.name
                          ? _c("h5", { staticClass: "mb-2" }, [
                              _vm._v(" " + _vm._s(item.name) + " "),
                            ])
                          : _c("h5", [_vm._v(" - ")]),
                        item
                          ? _c("VideoPlayer", {
                              staticClass: "mb-3",
                              attrs: {
                                lessonId: _vm.lesson.id,
                                saveTime: true,
                                video: item,
                                height: 380,
                                controls: true,
                              },
                            })
                          : _vm._e(),
                        item.description
                          ? _c("div", {
                              staticClass:
                                "viewTraining__additionalMaterials-item__description",
                              domProps: { innerHTML: _vm._s(item.description) },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }