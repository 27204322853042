var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.lesson.videoEmbedVimeoCode
    ? _c("iframe", {
        staticClass: "media",
        attrs: {
          src: _vm.lesson.videoEmbedVimeoCode,
          width: "100%",
          allow: "autoplay; fullscreen; picture-in-picture",
          allowfullscreen: "",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }