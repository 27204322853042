<template>
  <iframe
    v-if="lesson.videoEmbedVimeoCode"
    class="media"
    :src="lesson.videoEmbedVimeoCode"
    width="100%"
    allow="autoplay; fullscreen; picture-in-picture"
    allowfullscreen
  />
</template>
<script>
import Lesson from "@/assets/js/Lesson.class";

export default {
  name: 'VimeoIframeVideo',
  props: {
    lesson: {
      type: Lesson || Boolean
    },
  }
}
</script>

<style lang="scss" scoped>
.media {
  width: 100%;
  border: none;
  min-height: 31vw;
  margin-top: 24px;
  margin-bottom: 24px;

  @include media-breakpoint-up(md) {
    min-height: 31vw;
  }
}
</style>
