var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "h-100",
      on: {
        touchend: function ($event) {
          _vm.startTouchPosition = 0
        },
      },
    },
    [
      _vm.loadingTariffs
        ? _c("CSpinner", {
            staticClass: "loader",
            attrs: { color: "primary", size: "lg" },
          })
        : [
            _c(
              "div",
              { staticClass: "viewTraining" },
              [
                _c("div", { staticClass: "viewTraining__scroll" }, [
                  _c(
                    "div",
                    { staticClass: "header" },
                    [
                      _c("h1", { staticClass: "title mb-0 mr-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.homeCard ? _vm.homeCard.name : "Вебинар"
                            ) +
                            " "
                        ),
                      ]),
                      _vm.isAdmin
                        ? _c(
                            "CButton",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content:
                                      "Переключатель только для админов. Если глаз синий - виден весь контент, если серый - как для ученика",
                                  },
                                  expression:
                                    "{\n              content:\n                'Переключатель только для админов. Если глаз синий - виден весь контент, если серый - как для ученика',\n            }",
                                },
                              ],
                              on: { click: _vm.toggleAdminView },
                            },
                            [
                              _c("CIcon", {
                                staticClass: "header__icon",
                                class: {
                                  header__icon_closed: _vm.showForAdminAsUser,
                                },
                                attrs: { name: "eyeOpen" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "viewTraining__scroll-wrap" }, [
                    _c(
                      "div",
                      {
                        ref: "cardBlock",
                        staticClass: "viewTraining__cards pb-2",
                      },
                      _vm._l(_vm.cardData, function (item, idx) {
                        return _c(
                          "CCard",
                          {
                            key: "cards" + idx,
                            staticClass: "viewTraining__cards-item",
                            class: {
                              _active: _vm.selectedCard.id === item.id,
                              _closed: _vm.isLessonClosed(item),
                            },
                            style: `transform: translateX(${_vm.cardPosition}px);`,
                            on: {
                              click: function ($event) {
                                return _vm.lessonCardClickHandler(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "viewTraining__cards-item__header",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "viewTraining__cards-item__header__h d-flex",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(" " + _vm._s(item.theme) + " "),
                                    ]),
                                    item.approval
                                      ? _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: {
                                                  content:
                                                    _vm.approvalTooltipContent(
                                                      item.approval
                                                    ),
                                                  html: true,
                                                },
                                                expression:
                                                  "{\n                      content: approvalTooltipContent(item.approval),\n                      html: true,\n                    }",
                                              },
                                            ],
                                            staticClass:
                                              "viewTraining__approval",
                                          },
                                          [
                                            _c("CIcon", {
                                              class: {
                                                __active:
                                                  !_vm.isDarkMode &&
                                                  _vm.selectedCard.id ===
                                                    item.id,
                                              },
                                              attrs: { name: "check" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.live
                                      ? _c("div", { staticClass: "isLive" }, [
                                          _vm._v(" LIVE "),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              { staticClass: "viewTraining__cards-item__desc" },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            idx !== 0
                              ? _c(
                                  "transition",
                                  { attrs: { name: "fade", mode: "out-in" } },
                                  [
                                    _vm.new_lesson.some(
                                      (el) => el === item.id
                                    ) && !_vm.isLessonClosed(item)
                                      ? _c(
                                          "p",
                                          {
                                            key: "new-text",
                                            staticClass:
                                              "viewTraining__cards-item__new",
                                          },
                                          [_vm._v(" NEW ")]
                                        )
                                      : _vm._e(),
                                    !_vm.new_lesson.some(
                                      (el) => el === item.id
                                    ) && !_vm.isLessonClosed(item)
                                      ? _c(
                                          "div",
                                          {
                                            key: "new-svg",
                                            staticClass:
                                              "viewTraining__cards-item__svg",
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "viewTraining__cards-item__svg",
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  width: "28",
                                                  height: "28",
                                                  viewBox: "0 0 24 24",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    fill: "none",
                                                    d: "M0 0h24v24H0V0z",
                                                  },
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    d: "M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ]),
                ]),
                _vm.selectedCard
                  ? _c("LessonCard", {
                      ref: "lessonCard",
                      attrs: {
                        lesson: _vm.selectedCard,
                        primaryVideo: _vm.primaryVideo,
                      },
                      on: { timeout: _vm.disableNew },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }