<template>
  <div
    class="h-100"
    @touchend="startTouchPosition = 0"
  >
    <CSpinner
      v-if="loadingTariffs"
      color="primary"
      size="lg"
      class="loader"
    />
    <template v-else>
      <div class="viewTraining">
        <div class="viewTraining__scroll">
          <div class="header">
            <h1 class="title mb-0 mr-3">
              {{ homeCard ? homeCard.name : 'Вебинар' }}
            </h1>
            <CButton
              v-if="isAdmin"
              v-tooltip="{
                content:
                  'Переключатель только для админов. Если глаз синий - виден весь контент, если серый - как для ученика',
              }"
              @click="toggleAdminView"
            >
              <CIcon
                class="header__icon"
                :class="{ header__icon_closed: showForAdminAsUser }"
                name="eyeOpen"
              />
            </CButton>
          </div>
          <div class="viewTraining__scroll-wrap">
            <div
              class="viewTraining__cards pb-2"
              ref="cardBlock"
            >
              <CCard
                class="viewTraining__cards-item"
                :class="{ _active: selectedCard.id === item.id, _closed: isLessonClosed(item) }"
                v-for="(item, idx) in cardData"
                :style="`transform: translateX(${cardPosition}px);`"
                @click="lessonCardClickHandler(item)"
                :key="'cards' + idx"
              >
                <div class="viewTraining__cards-item__header">
                  <div class="viewTraining__cards-item__header__h d-flex">
                    <span>
                      {{ item.theme }}
                    </span>
                    <span
                      v-if="item.approval"
                      class="viewTraining__approval"
                      v-tooltip="{
                        content: approvalTooltipContent(item.approval),
                        html: true,
                      }"
                    >
                      <CIcon
                        :class="{ __active: !isDarkMode && selectedCard.id === item.id }"
                        name="check"
                      />
                    </span>
                    <div
                      v-if="item.live"
                      class="isLive"
                    >
                      LIVE
                    </div>
                  </div>
                  <!--span class="viewTraining__cards-item__header__date" v-if="item.live">
                    {{ item.startAtFormatted | formatDate('HH:mm UTC DD.MM.YYYY') }}
                  </span>
                  <span  class="viewTraining__cards-item__header__date" v-else>
                    {{ item.startAtFormatted | formatDate('DD.MM.YYYY') }}
                  </span-->
                </div>
                <span class="viewTraining__cards-item__desc">{{ item.name }}</span>
                <transition
                  v-if="idx !== 0"
                  name="fade"
                  mode="out-in"
                >
                  <p
                    key="new-text"
                    v-if="new_lesson.some((el) => el === item.id) && !isLessonClosed(item)"
                    class="viewTraining__cards-item__new"
                  >
                    NEW
                  </p>
                  <div
                    class="viewTraining__cards-item__svg"
                    v-if="!new_lesson.some((el) => el === item.id) && !isLessonClosed(item)"
                    key="new-svg"
                  >
                    <svg
                      class="viewTraining__cards-item__svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        d="M0 0h24v24H0V0z"
                      />
                      <path
                        d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z"
                      />
                    </svg>
                  </div>
                </transition>
              </CCard>
            </div>
          </div>
        </div>
        <LessonCard
          v-if="selectedCard"
          ref="lessonCard"
          :lesson="selectedCard"
          :primaryVideo="primaryVideo"
          @timeout="disableNew"
        />
      </div>
    </template>
  </div>
</template>

<script>
import VideoPlayer from '@/components/videoEncrypt/VideoPlayer'
import { mapGetters, mapState } from 'vuex'
import axios from 'axios'
import LessonCard from '@/components/training/LessonCard'
import Lesson from '@/assets/js/Lesson.class'
import moment from 'moment'
import VimeoIframeVideo from '@/components/training/VimeoIframeVideo'

export default {
  name: 'PageViewTraining',
  components: {
    LessonCard,
    VideoPlayer,
    VimeoIframeVideo,
  },
  computed: {
    primaryVideo() {
      if (this.selectedCard.videos.length) {
        return this.selectedCard.videos.find((item) => item?.primary)
      }
      return null
    },
    tgStudentsButtonShow() {
      if (this.$store.getters['user/getPermissions']('show_curator')) {
        return false
      }
      if (this.$store.getters['user/getPermissions']('view-education')) {
        return !this.userData.events.enter_tg_channel
      } else {
        return false
      }
    },
    isDarkMode() {
      return this.$store.getters.darkMode
    },
    screenWidth: function () {
      return window.screen.width
    },
    ...mapGetters({
      isMobile: 'mediaQuery/isMobile',
    }),
    ...mapState('user', ['userData']),
    isAdmin() {
      return this.$store.getters['user/getPermissions']('control-education')
    },
    randKey() {
      return Math.random()
    },
  },
  data() {
    return {
      eventId: null,
      selectedTab: 0,
      activeMaterials: [],
      cardPosition: 0,
      selectedCard: false,
      startTouchPosition: 0,
      startCardPosition: 0,
      loadingTariffs: false,
      new_lesson: [],
      showForAdminAsUser: false,
      homeCard: {},
      cardData: [],
      accordionData: [],
    }
  },
  mounted() {
    this.$root.$on('tabGroupe-changeTab', (id) => {
      this.selectedTab = id
    })
    this.getData()
  },
  watch: {
    selectedCard() {
      if (this.$refs.lessonCard) this.$refs.lessonCard.$el.scrollTop = 0
    },
  },
  methods: {
    lessonCardClickHandler(item) {
      if (!this.isLessonClosed(item)) {
        this.selectedCard = item
      }
    },
    disableNew(id) {
      if (this.$store.getters['user/getPermissions']('view-education')) {
        axios.post(`/api/v2/lessons/${id}/old`).then(() => {
          this.new_lesson = this.new_lesson.filter((el) => el != id)
        })
      }
    },

    toggleAdminView() {
      this.showForAdminAsUser = !this.showForAdminAsUser
    },
    isLessonClosed(lesson) {
      if (this.eventId === lesson.id) {
        return false
      }
      if (this.isAdmin && !this.showForAdminAsUser) {
        return false
      }
      if (!lesson.publishedAt) {
        return true
      }
      if (!lesson.published) {
        return true
      }
    },
    async getData() {
      this.loadingTariffs = true
      const { data } = await axios.get(`/api/v2/events/${this.$route.params.slug}`)
      if (data?.data) {
        this.eventId = data.data.id
        this.new_lesson = data.data.new_lesson
        data.data.children?.forEach((lesson) => {
          this.cardData.push(new Lesson(lesson))
          //this.accordionData.push(new Lesson(lesson))
        })
        this.cardData.sort((el1, el2) => {
          const el1IsNew = this.new_lesson.some((id) => id === el1.id)
          const el2IsNew = this.new_lesson.some((id) => id === el2.id)
          if (el1IsNew && !el2IsNew) {
            return -1
          }
          if (!el1IsNew && el2IsNew) {
            return 1
          }
          return el1.order - el2.order
        })
        this.homeCard = new Lesson(data.data)
        //this.cardData.unshift(new Lesson(data.data))

        this.selectedCard = this.cardData[0]
      }
      this.loadingTariffs = false
    },
    showDate(isLive) {
      if (this.screenWidth < 768) return !isLive
      return false
    },
    approvalTooltipContent(approval) {
      return `<div class='d-flex flex-column align-items-start'><span>${this.$t(
        'lk.curator.hwTooltip'
      )}</span> <span>${moment(approval?.created_at).format('DD.MM.YYYY')}</span></div>`
    },
  },
}
</script>

<style lang="scss" scoped>
.loader {
  display: block;
  margin: 300px auto;
}

.viewTraining {
  position: relative;
  @include media-breakpoint-up(lg) {
    height: calc(100% - 2rem);
    margin-top: 0;
  }
  &__scroll {
    @include media-breakpoint-up(lg) {
      overflow: hidden;
      position: relative;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      max-width: 22.9375rem;
    }
    &::before {
      content: '';
      display: none;
      width: calc(98% - 0.5625rem);
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0.5rem;
      background: var(--base-card-bg);
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
    &-wrap {
      @include media-breakpoint-up(lg) {
        overflow: auto;
        flex: 1 1 auto;
      }
    }
  }
  &__cards {
    display: flex;
    overflow: auto;
    width: 100%;
    gap: 12px;
    @include media-breakpoint-up(md) {
      // width: calc(100% + 60px);
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      gap: 0.625rem;
      width: 98%;
      transform: scale(-1, 1);
    }

    &-item {
      min-width: 200px;
      min-height: 5.21875rem;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      padding: 8px 12px;
      color: var(--white);
      cursor: pointer;
      margin-bottom: 0;
      position: relative;
      background: var(--second-subscribe-card-bg);

      &__new {
        background: var(--primary);
        border: solid 1.5px var(--base-card-bg-dark-theme);
        border-radius: 10px;
        padding: 3px;
        margin: 0 auto;
        margin-top: auto;

        @include media-breakpoint-up(md) {
          position: absolute;
          bottom: 6px;
          right: 12px;
        }
      }

      &__svg {
        fill: var(--white);
        margin: 0 auto;
        margin-top: 5px;

        @include media-breakpoint-up(md) {
          margin: 0;
          position: absolute;
          right: 10px;
          bottom: 6px;
        }
      }

      &._closed {
        pointer-events: none;

        &:after {
          content: '';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
          opacity: 0.7;
          background: #353d44;
        }
      }

      @include media-breakpoint-up(md) {
        min-width: 305px;
        min-height: 5.9375rem;
        font-weight: 400;
        font-size: 14px;
        padding: 12px 16px;
      }

      @include media-breakpoint-up(lg) {
        min-width: 0;
        min-height: 5rem;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
      }
      @include media-breakpoint-down(md) {
        width: fit-content;
      }

      &:first-child {
        // margin-left: 30px;

        @include media-breakpoint-up(lg) {
          margin-left: 0;
        }
      }

      &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;
        gap: 6px;
        align-items: center;

        &__date {
          text-align: center;

          @include media-breakpoint-up(md) {
            text-align: left;
          }
        }

        &__h {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;

          @include media-breakpoint-up(md) {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
          }
        }

        @include media-breakpoint-up(md) {
          gap: 10px;
          flex-direction: row;
          align-items: flex-start;
        }
      }

      &__desc {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
          margin-top: 6px;
          padding-right: 30px;
        }

        @include media-breakpoint-up(lg) {
          margin-top: 8px;
        }
      }
    }

    ._active {
      background: var(--primary);
    }
  }
  &__inner {
    &__accordion {
    }

    &__tg::before {
      position: relative;
      content: '✓';
      font-size: 28px;
      top: 4px;
      color: var(--success);
    }

    &.card {
      @include media-breakpoint-up(lg) {
        padding-top: 28px;
      }
    }
  }

  &__curators {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--second-card-bg);
    flex-direction: row;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-top: 16px;
    padding: 8px 20px 8px 16px;

    @include media-breakpoint-up(md) {
      font-size: 20px;
      line-height: 24px;
      padding: 22px 32px;
    }

    &__telegram {
      color: var(--white) !important;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .c-icon-custom-size {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__approval {
    margin-left: 8px;
    color: $success;
    vertical-align: bottom;
    .c-icon {
      width: 20px;
      height: 20px;
    }
  }

  .__active {
    fill: #0f0;
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0 1.5rem;
  flex: none;
  @include media-breakpoint-up(lg) {
    transform: scale(-1, 1);
    width: 98%;
    min-height: 3.15rem;
    padding: 0 1.45rem;
    margin-bottom: 0.5rem;
  }

  &__icon {
    cursor: pointer;
    color: var(--primary);

    &_closed {
      color: var(--dark);
    }
  }

  .title {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: normal;

    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.2rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.5rem;
    }
  }
}

.isLive {
  height: fit-content;
  padding: 3.5px;
  background: var(--market-volume-danger);
  font-size: 12px;

  // @include media-breakpoint-up(md) {
  margin-left: 10px;
  // }
}

.isLive::before {
  content: '●';
  margin-right: 4px;
}

.acc-enter-active,
.acc-leave-active {
  margin-top: 12px;

  @include media-breakpoint-up(md) {
    margin-top: 24px;
  }
}

.acc-enter,
.acc-leave-to {
  margin-top: 0px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
