<template>
  <CCard class="viewTraining__inner">
    <slot>
      <span class="viewTraining__inner__h">{{ lesson ? lesson.name : 'Тема обучения' }}</span>
      <VideoPlayer
        v-if="primaryVideo"
        :lessonId="lesson.id"
        :saveTime="true"
        :video="primaryVideo"
        :height="380"
        :controls="true"
      ></VideoPlayer>
      <VimeoIframeVideo v-if="!primaryVideo" :lesson="lesson"/>
      <CAccordeon v-if="lesson.chat"
                  :accordion-data="[{id: 0, theme:'История чата', shortDescription: getLineWrap}]"></CAccordeon>
      <CButton v-if="!lesson.chat && !primaryVideo && lesson.streamUrl" class="viewTraining__chat-btn" color="primary"
               @click="openChat(lesson.chatEmbedVimeoCode)">Chat
      </CButton>
      <template>
        <span class="viewTraining__inner__h" v-if="lesson.has_homework">
          {{ $t('lk.training.homeWork') }}
        </span>

        <span v-html="lesson.descriptionFormatted" class="viewTraining__inner__desc">
        </span>
      </template>
      <div class="viewTraining__additionalMaterials mt-5" v-if="additionalMaterials.length">
        <span class="viewTraining__inner__h">
          {{ $t('lk.videoEncrypt.additionalMaterials') }}
        </span>
        <div
          v-for="(item, index) in additionalMaterials"
          :key="index"
          class="viewTraining__additionalMaterials-item mt-4"
        >
          <h5 v-if="item.name" class="mb-2">
            {{ item.name }}
          </h5>
          <h5 v-else>
            -
          </h5>
          <VideoPlayer
            v-if="item"
            class="mb-3"
            :lessonId="lesson.id"
            :saveTime="true"
            :video="item"
            :height="380"
            :controls="true"
          ></VideoPlayer>
          <div v-if="item.description" v-html="item.description" class="viewTraining__additionalMaterials-item__description">
          </div>
        </div>
      </div>
    </slot>
  </CCard>
</template>
<script>
import VideoPlayer from '@/components/videoEncrypt/VideoPlayer'
import VimeoIframeVideo from "@/components/training/VimeoIframeVideo";
import Lesson from "@/assets/js/Lesson.class";
import CAccordeon from "@/components/training/LessonAccordionComponent"

export default {
  name: 'LessonCard',
  components: {
    VimeoIframeVideo,
    VideoPlayer,
    CAccordeon
  },
  props: {
    lesson: {
      type: Lesson || Boolean,
      default: () => {
      }
    },
    primaryVideo: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      playerModalMime: 'application/x-mpegURL',
      timerId: null
    }
  },
  computed: {
    additionalMaterials() {
      return Array.isArray(this.lesson.videoDescFormatterd) ? this.lesson.videoDescFormatterd.filter((item) => !item?.primary) : []
    },
    getLineWrap() {
      return this.lesson.chat.split('\n').join('<br>')
    }
  },
  methods: {
    openChat(link) {
      window.open(link, '', 'width=200, height=600')
    },
  },
  watch: {
    lesson() {
      if (this.timerId) clearInterval(this.timerId)
      this.timerId = setTimeout(() => {
        this.$emit('timeout', this.lesson.id)
      }, 60000)
      if (!this.lesson) clearInterval(this.timerId)
    }
  }
}
</script>

<style lang="scss">
.viewTraining {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  height: calc(100% - 4rem);

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: calc(47px + 1.5rem);
    height: calc(100% - 6rem);
  }

  &__scroll {
    width: 100%;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(lg) {
      flex: 8;
      transform: scale(-1, 1);
      height: 100%;
      overflow: auto;
      width: auto;
      margin-bottom: 0rem;
    }
  }

  &__inner {
    width: 100%;
    padding: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    height: 100%;
    overflow: auto;

    @include media-breakpoint-up(md) {
      padding: 24px;
    }

    @include media-breakpoint-up(lg) {
      padding: 46px 52px;
      flex: 14;
    }

    &__h {
      font-weight: 700;
      font-size: 21px;
      line-height: 26px;
      margin-bottom: 12px;
    }

    &__desc {
      margin-top: 12px;
      margin-bottom: 12px;

      audio,
      iframe,
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__curator {
    //@extend .accordion-study__item;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--second-card-bg);
    flex-direction: row;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-top: 16px;

    @include media-breakpoint-up(md) {
      font-size: 20px;
      line-height: 24px;
    }

    &__telegram {
      color: var(--white) !important;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      &:hover {
        text-decoration: none;
      }

      &__icon {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &__additionalMaterials {
    &-item {
      &__description {
        audio,
        iframe,
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}

.viewTraining__chat-btn {
  width: 25%;
  margin-left: auto;
}

.accordion-study__block {
  margin-right: -30px;
  padding-right: 30px
}

</style>
